import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import PageHead from 'src/components/head/PageHead'
import {
  NewsletterIcon,
  SubscribeInvestorForm,
  SubscribeSuspended,
} from 'src/components/newsletter'

import { SUBSCRIBE_SUSPENDED } from 'src/constants'

// Styles & Images
import 'static/newsletter/scss/styles.scss'

// Partials
const Background = () => (
  <>
    <div className="x-page-newsletter__background" />
    <div className="x-page-newsletter__background-x-container">
      <img src="/img/header-x-long.svg" />
    </div>
  </>
)

// Main component:
const InvestorNewsletter = () => {
  const [animate, setAnimate] = useState(false)

  useEffect(() => setTimeout(setAnimate(true), 1), [])

  return (
    <div
      className={classNames('x-page-newsletter', {
        'x-page-newsletter--animating': animate,
      })}
    >
      <Background />

      <div className="x-page-newsletter__content">
        <NewsletterIcon animate={animate} />

        {SUBSCRIBE_SUSPENDED ? (
          <SubscribeSuspended animate={animate} />
        ) : (
          <SubscribeInvestorForm animate={animate} />
        )}
      </div>
    </div>
  )
}

export default InvestorNewsletter

// <head> component:
export function Head() {
  return <PageHead page="investor-newsletter" />
}
